<template>
  <div class="user-management">
    <el-row class="ctrArea">
      <el-col :span="2" :offset="18">
         <el-link
          type="primary"
          class="muban_download"
          download="实际居住人口表.xlsx"
          href="/epidemicControl/实际居住人口表.xlsx"
          >下载模板</el-link
        >
      </el-col>
      <el-col :span="2">
        <el-upload
          action=""
          :before-upload="beforeUpload"
          :limit="1"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :show-file-list="false"
          ref="batchImportUploadBtn"
          :disabled="batchImportUploadIng"
          :http-request="fileuploadHandle"
        >
          <el-button
            type="primary"
            size="mini"
            :disabled="batchImportUploadIng"
            :loading="batchImportUploadIng"
            >批量导入居住人口信息</el-button
          >
        </el-upload>
      </el-col>
    </el-row>

    <div style="clear: both"></div>
    <div class="search-bar">
      <el-form
        :inline="true"
        :model="listQuery"
        ref="departmentInfo"
      >
        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.name"
            placeholder="姓名"
            maxlength="32"
            style="width: 200px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.contact"
            placeholder="请输入电话"
            maxlength="11"
            style="width: 200px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item style="margin-left: 20px">
          <el-input
            v-model.trim="listQuery.idNumber"
            placeholder="证件号"
            maxlength="32"
            style="width: 200px"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary"  @click="searchBtn"
            >搜索</el-button
          >
          <el-button  @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="tableHeight"
      border
    >
      <el-table-column type="index" label="序号" width="65"> </el-table-column>

      <el-table-column
        prop="household"
        label="家庭户号/集体户号"
        width="90"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        prop="relationshipWithHouseholder"
        label="与户主关系"
        width="100"
      >
      </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="gender" label="性别"> </el-table-column>
      <el-table-column prop="age" label="年龄"> </el-table-column>

      <el-table-column
        prop="nationality"
        label="国籍"
        show-overflow-tooltip
        width="80"
      >
      </el-table-column>
      <el-table-column prop="national" label="民族"> </el-table-column>
      <el-table-column prop="idType" label="证件类型"> </el-table-column>
      <el-table-column
        prop="idNumber"
        label="证件号码"
        show-overflow-tooltip
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="contact"
        label="联系方式"
        show-overflow-tooltip
        width="120"
      >
      </el-table-column>
      <el-table-column prop="populationType" label="人口类型">
      </el-table-column>
      <el-table-column prop="livingState" label="居住状态"> </el-table-column>
      <el-table-column prop="keyGroups" label="18类重点人群" width="110">
      </el-table-column>
      <el-table-column prop="symptomGroups" label="五大症状人群" width="110">
      </el-table-column>
      <el-table-column prop="fillCity" label="填报市"> </el-table-column>
      <el-table-column prop="fillCounty" label="填报县"> </el-table-column>
      <el-table-column prop="fillTownship" label="填报乡"> </el-table-column>
      <el-table-column prop="housingNature" label="住房性质"> </el-table-column>
      <el-table-column prop="neighborhoodCommittee" label="居委会">
      </el-table-column>
      <el-table-column prop="street" label="街（路）"> </el-table-column>
      <el-table-column prop="community" label="小区（单位、酒店）">
      </el-table-column>
      <el-table-column prop="buildingNumber" label="楼号、单元及房间号">
      </el-table-column>
      <el-table-column prop="administrativeVillages" label="行政村">
      </el-table-column>
      <el-table-column prop="naturalVillage" label="自然村"> </el-table-column>

      <el-table-column label="操作" fixed="right" v-slot="scope" width="80">
        <div class="option-list-box">
          <el-link
            :underline="false"
            type="danger"
            class="ml10r"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @current-change="getTableList"
        :pageNum-page.sync="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryResidentpersonInfoByPage,
  deleteResidentpersonInfoById,
  importResidentpersonInfosPath,
} from "../../../api/epidemiccontrolResidentpersonInfo/index";
import { fileupload } from "../../../api/uploadfile/index";
export default {
  data() {
    return {
      batchImportUploadIng: false, //正在处理批量上传
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        name: "", //用户名
        contact: "", //电话
        idNumber: "", //证件号
      },
      total: 0,
      tableData: [],
    };
  },
  mounted() {
    this.getTableList(1);
  },

  filters: {},
  methods: {
    fileuploadHandle(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          if (res.code == 1) {
            console.log("成功");
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            this.$refs["batchImportUploadBtn"].clearFiles();
            let data = res.data;
            //告诉后台，文件已经上传成功以及文件信息，准备批量处理
            let paramers = {
              path: data.nginxFileReadPath,
            };
            importResidentpersonInfosPath(paramers)
              .then((res) => {
                this.batchImportUploadIng = false;

                if (res.code == 1) {
                 this.$message.success(res.msg);
                  //重新查询数据
                  this.getTableData();
                } else if (res.code == 1001) {
                  this.$message.error("数据操作错误");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((e) => {
                this.batchImportUploadIng = false;
                // this.$message.error(e);
              });
          } else if (res.code == -1) {
            this.batchImportUploadIng = false;
            //未登陆
            this.$router.push("/");
          } else {
            this.batchImportUploadIng = false;
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.batchImportUploadIng = false;
        });
    },
    beforeUpload(file) {
      this.batchImportUploadIng = true;
    },
    //列表查询
    getTableList(val) {
      this.listQuery.pageNum = val;
      this.getTableData();
    },
    getTableData() {
      queryResidentpersonInfoByPage(this.listQuery).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetBtn() {
      this.listQuery.pageNum = 1;
      this.listQuery.sampleNumber = "";
      this.listQuery.contact = "";
      this.listQuery.idNumber = "";
      this.getTableList(1);
    },
    //搜索按钮
    searchBtn() {
      this.listQuery.pages = 1;
      this.getTableList(1);
    },

    //删除
    deleteOption(row) {
      this.$confirm("确定要删除此数据", "数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: row.id,
          };
          deleteResidentpersonInfoById(data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getTableList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.user-management {
  .search-top {
    height: 40px;
    float: right;
  }

  .search-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
  .mt20r {
    margin-top: 20px;
  }
}
</style>